@import url('https://fonts.googleapis.com/css2?family=Akaya+Telivigala&family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Syne:wght@400..800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body{
  width: 100vw;
  height: 100vh;
  font-family: "Akaya Telivigala", system-ui;
}

.white-background {
  background-color: rgb(255, 254, 253);
  color: rgb(31, 33, 34);
  background-image: url(https://phenomenonstudio.com/wp-content/webp-express/webp-images/themes/phenomenon/img/bg.png.webp);
  background-color: rgb(255, 254, 253);
  color: rgb(31, 33, 34);
  /* background-image: url(./assets/hera.mp4); */
}
.orange-background {
  background-color: #F2751A;
backface-visibility: visible;
background-attachment: fixed;
  color: rgb(31, 33, 34);
  background-image: url(https://phenomenonstudio.com/wp-content/webp-express/webp-images/themes/phenomenon/img/bg.png.webp);
}
.lemon-background {
  background-color: rgb(186, 172, 42);
backface-visibility: visible;
background-attachment: fixed;
  color: rgb(31, 33, 34);
  background-image: url(https://phenomenonstudio.com/wp-content/webp-express/webp-images/themes/phenomenon/img/bg.png.webp);
}

.black-gradient {
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #434343,
    #000000
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #434343,
    #000000
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.hash-span {
  margin-top: -100px;
  padding-bottom: 100px;
  display: block;
}
.image-container {
  perspective: 1000px;
  transition: transform 0.5s;
}

.image-container:hover {
  transform: perspective(1000px) rotateY(360deg);
}

img {
  width: 100%;
  height: auto;
}

.viewed.fadein {
  animation-name: fadein;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  -webkit-backface-visibility: hidden;
}

.loading-screen{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  color: #fff;
  pointer-events: none;
  font-family: "Akaya Telivigala", system-ui;

}

.counter{
  position: fixed;
  left: 50px;
  bottom: 50px;
  display: flex;
  height: 100px;
  font-size: 100px;
  line-height: 102px;
  font-weight: 400;
  font-family: "Akaya Telivigala", system-ui;

  clip-path: polygon(0 0, 100% 0 , 100% 100px, 0 100px);
}

.counter-1,
.counter-2,
.counter-3{
  position: relative;
  top:-15px;

}

.num1offset1{
  position: relative;
  right: -25px;
}
.num1offset2{
  position: relative;
  right: -10px;
}

.loader{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 50px;
  transform: translate(-50%, -50%);
  display: flex;
  background-color: rgb(80, 80, 80);
}

.loader-1{
  position: relative;
  background-color: #fff;
  width: 200px;
}

.loader-2{
  position: relative;
  background-color: #fff;
  width: 100px;
}

.bar{
  height: 50px;

}
.website-content{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header{
  position: relative;
  width: max-content;
  height: max-content;
}

.h1{
  display: flex;
}

h1{
  font-size: 80px;
}

.header .h1 h1{
  text-align: center;
  position: relative;
  top: 80px;
  margin: 0 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.header-revealer{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.header-revealer:after{
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 105%;
  height: 110%;
  background-color: #fff;
  top: 80px;
}





.main-p{
  width: 100%;
  height: 100vh;
  background-color: rgb(61, 61, 61);
}

#main{
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: aquamarine;
  overflow: hidden;
}

#top{
  position: absolute;
  top: 0%;
  width: 100%;
  height: 50vh;
  background-color: rgb(255, 255, 255);
  z-index: 9;
  overflow: hidden;
}

#center{
  position: relative;
  width: 100%;
  height: 100vh;
  transform-origin: center;
  background-color: rgb(0, 0, 0);
  transition: all cubic-bezier(0.19, 1, 0.22, 1)1s;
  overflow: hidden;
}

#bottom{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50vh;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
}

#main h1{
  font-family: "Akaya Telivigala", system-ui;
  font-size: 22vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#top-h1{
  bottom: 50%;
}

#bottom-h1{
  top: 0% !important;
}

.content{
  margin-top: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  color: #fff;
  gap: 4vh;
}

.content h4{
  font-size: 1vw;
  font-family: "Akaya Telivigala", system-ui;
}

.content h3{
  width: 22%;
  font-size: 3vw;
  font-family: "Akaya Telivigala", system-ui;
  text-align: center;
  font-weight: 400;
}

.content .btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7vw;
  height: 2vw;
  border-radius: 50px;
  background-color: #fff;
  color: #0d0d0d;
  font-family: "Akaya Telivigala", system-ui;
}

.content h2{
  font-size: 20vw;
  font-family: "Akaya Telivigala", system-ui;
}






.contains{
	max-width: 1170px;
	margin:auto;
  font-family: "Akaya Telivigala", system-ui;
}
.row{
	display: flex;
	flex-wrap: wrap;
}
ul{
	list-style: none;
}
.footer{
  font-family: "Akaya Telivigala", system-ui;
	background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #434343,
    #000000
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #434343,
    #000000
  );
    padding: 70px 0;
}
.footer-col{
   width: 25%;
   padding: 0 15px;
}
.footer-col h4{
	font-size: 18px;
	color: #ffffff;
	text-transform: capitalize;
	margin-bottom: 35px;
	font-weight: 500;
	position: relative;
  font-family: "Akaya Telivigala", system-ui;
}
.footer-col h4 ::before{
	content: '';
	position: absolute;
	left:0;
	bottom: -10px;
	background-color: #ff6f00;
	height: 2px;
	box-sizing: border-box;
	width: 50px;
}
.footer-col ul li :not(:last-child){
	margin-bottom: 10px;
}
.footer-col ul li {
	font-size: 16px;
	text-transform: capitalize;
	color: #ffffff;
	text-decoration: none;
	font-weight: 300;
	color: #bbbbbb;
	display: block;
	transition: all 0.3s ease;
}
.footer-col ul li :hover{
	color: #ffffff;
	padding-left: 8px;
}
.footer-col .social-links {
	display: inline-block;
	height: 40px;
	width: 40px;
	background-color: rgba(255,255,255,0.2);
	margin:0 10px 10px 0;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	color: #ffffff;
	transition: all 0.5s ease;
}
.footer-col .social-links :hover{
	color: #24262b;
	background-color: #ffffff;
}

/*responsive*/
@media(max-width: 767px){
  .footer-col{
    width: 50%;
    margin-bottom: 30px;
}
}
@media(max-width: 574px){
  .footer-col{
    width: 100%;
}
}


.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  display: block;
  padding: 10px;
  color: #333;
  text-decoration: none;
}

.dropdown-content :hover {
  background-color: #f4f4f4;
}




@media screen and (max-width: 768px) {
  .dropdown:hover .dropdown-content {
      display: none;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0%;
  z-index: 2;
  background: #fff;
}

.first {
  top: 0;
}

.second {
  top: 33.3%;
}

.third {
  top: 66.6%;
}

.bg3{
  background-image: url("./assets/6.webp")
}