@import url('https://fonts.googleapis.com/css2?family=Akaya+Telivigala&family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Syne:wght@400..800&display=swap');


:root {
  --primary-color: rgba(1, 19, 27, 1);
  --secondary-color: #ff0a0a;
  --white: #ffffff;
  --max-width: 1200px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  transition: 0.3s;
}
/* .product-title {
  color: #f2d16b;
  font-weight: 900;
  letter-spacing: -10px;
  font-size: 280px;
} */

.body {
  font-family: "Akaya Telivigala", system-ui;
  font-weight: 400;
  font-style: normal;
  min-height: 100vh;
  background-image: url("https://img.freepik.com/premium-photo/woman-holding-credit-card-phone-with-green-card-her-hand-generative-ai_868797-570.jpg?w=900");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
}
.bg1 {
  font-family: "Akaya Telivigala", system-ui;
  /* font-weight: 400;
  font-style: normal; */
  min-height: 100vh;
  /* background-image: url("../assets/4.png"); */
  background-image: url("../../assets/4.webp");
  /* background-image: url("../assets/5.webp"); */
  /* background-image: url("../assets/6.webp"); */
  /* background-image: url("../assets/"); */
  /* background-color: #ff6f00; */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

.container {
  max-width: var(--max-width);
  margin-inline: auto;
}

nav {
  position: fixed;
  isolation: isolate;
  width: 100%;
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #434343,
    #000000
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #434343,
    #000000
  );
    /* padding: 70px 0; */
  z-index: 9;
}

.nav__bar {
  width: 100%;
  max-width: var(--max-width);
  margin-inline: auto;
}

.nav__header {
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #434343,
    #000000
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #434343,
    #000000
  );
    /* padding: 70px 0; */
}

.nav__logo a {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--white);
}

.nav__logo a span {
  color: var(--secondary-color);
}

.nav__menu__btn {
  font-size: 1.5rem;
  color: var(--white);
  cursor: pointer;
}

.nav__links {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  padding: 2rem;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #434343,
    #000000
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #434343,
    #000000
  );
    padding: 70px 0;
  transition: 0.5s;
  z-index: -1;
  transform: translateY(-100%);
}

.nav__links.open {
  transform: translateY(0);
}

.nav__links a {
  white-space: nowrap;
  color: var(--white);
  transition: 0.3s;
}

.nav__links a:hover {
  color: var(--secondary-color);
}

.contents {
  position: relative;
  isolation: isolate;
  min-height: 100vh;
  max-width: calc(var(--max-width) / 2);
  margin-inline: auto;
  padding: 8rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.contents::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(138, 7, 14, 0.701);
  z-index: -1;
}

.contents h1 {
  margin-bottom: 2rem;
  font-size: 4rem;
  font-weight: 700;
  line-height: 4rem;
  color: var(--white);
}

.contents h4 {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 200;
  color: var(--white);
}

.contents h2 {
  margin-bottom: 5px;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--white);
}

.contents h6 {
  margin-bottom: 1rem;
  font-size: 0.8rem;
  font-weight: 400;
  color: #fff;
}

.contents h6 span {
    color: var(--secondary-color);
  font-weight: 600;
}

.contents form {
  margin-bottom: 2rem;
  width: 100%;
  max-width: 450px;
  margin-inline: auto;
  display: flex;
  align-items: center;
  background-color: var(--white);
}

.contents input {
  width: 100%;
  padding: 0.5rem 0.75rem;
  outline: none;
  border: none;
  font-size: 1rem;
  color: var(--primary-color);
  background-color: transparent;
}

.contents button {
  padding: 5px 20px;
  outline: none;
  border: none;
  font-size: 1.75rem;
  color: var(--white);
  background-color: var(--secondary-color);
  cursor: pointer;
}

.contents p {
  margin-bottom: 4rem;
  font-weight: 200;
  color: var(--white);
  line-height: 1.75rem;
}

.socials {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.socials a {
  font-size: 1.5rem;
  color: var(--primary-color);
}

.socials a:hover {
  color: var(--white);
}

@media (width > 540px) {
  .contents h1 {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }
  .bg1 {
    font-family: "Akaya Telivigala", system-ui;
    /* font-weight: 400;
    font-style: normal; */
    /* min-height: 100vh; */
    /* background-image: url("../assets/4.png"); */
    background-image: url("../../assets/4.webp");
    /* background-image: url("../assets/5.webp"); */
    /* background-image: url("../assets/6.webp"); */
    /* background-image: url("../assets/"); */
    /* background-color: #ff6f00; */
    background-position:  center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;
  }
  .contents h4 {
    font-size: 1.75rem;
  }

  .contents h2 {
    font-size: 1.75rem;
  }
}

@media (width > 768px) {
  .bg1 {
    font-family: "Akaya Telivigala", system-ui;
    /* font-weight: 400;
    font-style: normal; */
    /* min-height: 100vh; */
    /* background-image: url("../assets/4.png"); */
    background-image: url("../../assets/4.webp");
    /* background-image: url("../assets/5.webp"); */
    /* background-image: url("../assets/6.webp"); */
    /* background-image: url("../assets/"); */
    /* background-color: #ff6f00; */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;
  }
  nav {
    position: static;
    background-color: rgba(1, 19, 27, 1);
  }

  .nav__bar {
    padding: 2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
  }

  .nav__header {
    padding: 0;
    background-color: transparent;
  }

  .nav__menu__btn {
    display: none;
  }

  .nav__links {
    position: static;
    padding: 0;
    flex-direction: row;
    justify-content: flex-end;
    background-color: transparent;
    transform: none;
    z-index: unset;
  }

  .contents {
    padding: 5rem 1rem;
    align-items: flex-start;
    text-align: left;
    margin-inline-start: unset;
  }

  .contents h1 {
    font-size: 5rem;
    line-height: 5rem;
  }

  .contents h4 {
    font-size: 2rem;
  }

  .contents h2 {
    font-size: 2rem;
  }

  .contents form {
    margin-inline-start: unset;
  }
}



.contains{
	max-width: 1170px;
	margin:auto;
}
.row{
	display: flex;
	flex-wrap: wrap;
}
ul{
	list-style: none;
}
.footer{
	background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #434343,
    #000000
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #434343,
    #000000
  );
    /* padding: 70px 0; */
    padding: 70px 0;
}
.footer-col{
   width: 25%;
   padding: 0 15px;
}
.footer-col h4{
	font-size: 18px;
	color: #ffffff;
	text-transform: capitalize;
	margin-bottom: 35px;
	font-weight: 500;
	position: relative;
}
.footer-col h4::before{
	content: '';
	position: absolute;
	left:0;
	bottom: -10px;
	background-color: #ff6f00;
	height: 2px;
	box-sizing: border-box;
	width: 50px;
}
.footer-col ul li:not(:last-child){
	margin-bottom: 10px;
}
.footer-col ul li {
	font-size: 16px;
	text-transform: capitalize;
	color: #ffffff;
	text-decoration: none;
	font-weight: 300;
	color: #bbbbbb;
	display: block;
	transition: all 0.3s ease;
}
.footer-col ul li a:hover{
	color: #ffffff;
	padding-left: 8px;
}
.footer-col .social-links a{
	display: inline-block;
	height: 40px;
	width: 40px;
	background-color: rgba(255,255,255,0.2);
	margin:0 10px 10px 0;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	color: #ffffff;
	transition: all 0.5s ease;
}
.footer-col .social-links a:hover{
	color: #24262b;
	background-color: #ffffff;
}

/*responsive*/
@media(max-width: 767px){
  .footer-col{
    width: 50%;
    margin-bottom: 30px;
}
}
@media(max-width: 574px){
  .footer-col{
    width: 100%;
}
}


.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  display: block;
  padding: 10px;
  color: #333;
  text-decoration: none;
}

.dropdown-content a:hover {
  background-color: #f4f4f4;
}




@media screen and (max-width: 768px) {
  .dropdown:hover .dropdown-content {
      display: none;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0%;
  z-index: 2;
  background: #fff;
}

.first {
  top: 0;
}

.second {
  top: 33.3%;
}

.third {
  top: 66.6%;
}